.three-dots svg {
    margin-bottom: 150px;
}

.sample-question {
    padding: 10px;
    margin: 10px
}

.sample-question:hover {
    border-radius: 12px; 
    box-shadow: 3px 3px 16px 0px #00000021;
}
